import {useStaticQuery, graphql} from 'gatsby';

type BrandSpecificLinksStaticQuery = {
  brandContent: {
    brandSpecificLinks: {
      contactNumber: string;
      contactUs?: string;
      privacyPolicyExternal?: string;
    };
  };
  commonContent: {
    privacyPolicyNIB: string;
  };
};

export const useBrandSpecificLinksStatic = () => {
  const content = useStaticQuery<BrandSpecificLinksStaticQuery>(
    graphql`
      query BrandSpecificLinksStaticQuery {
        brandContent {
          brandSpecificLinks {
            contactNumber
            contactUs
            privacyPolicyExternal
          }
        }
        commonContent {
          privacyPolicyNIB
        }
      }
    `
  );
  return {
    contactNumber: content.brandContent.brandSpecificLinks.contactNumber,
    contactUs: content.brandContent.brandSpecificLinks.contactUs,
    privacyPolicyExternal: content.brandContent.brandSpecificLinks.privacyPolicyExternal,
    privacyPolicyNIB: content.commonContent.privacyPolicyNIB
  };
};
