import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getFeatureTogglesError, getFetchedFeatureToggles} from '../../redux/featureToggles/selectors';
import {fetchProductPrices} from '../../redux/pricing/thunks';
import {ProductTypes} from '../../redux/pricing/types';
import {getCompany, getFetchedSession} from '../../redux/session/selectors';
import {getState} from '../../redux/session/selectors/contactDetails';
import {getIncomeTier} from '../../redux/session/selectors/governmentDetails';
import {getPaymentFrequency} from '../../redux/session/selectors/paymentDetails';
import {getHasPartner, getPartnerDob, getPolicyHolderDob, getScale} from '../../redux/session/selectors/personalDetails';
import {getExcess} from '../../redux/session/selectors/productSelection';
import {usePPCDateStatic} from '../UsePPCDateStatic';
import {ProductExcesses} from '../UseProductExcessesStatic';
import {ProductIds} from '../UseProductIdsStatic';
import {useShallowEqualSelector} from '../UseShallowEqualSelector';

export const useFetchProductPrices = (productType: ProductTypes, staticProducts, productExcesses: ProductExcesses[] = []) => {
  const hasFetchedSession: boolean | undefined = useShallowEqualSelector(getFetchedSession);

  const ppcDate = usePPCDateStatic();
  const scale = useShallowEqualSelector(getScale);
  const state = useShallowEqualSelector(getState);
  const frequency = useShallowEqualSelector(getPaymentFrequency);
  const excess = useShallowEqualSelector(getExcess);
  const company = useShallowEqualSelector(getCompany);
  const hasPartner = useShallowEqualSelector(getHasPartner);
  const annualIncomeTier = useShallowEqualSelector(getIncomeTier);
  const dateOfBirth = useShallowEqualSelector(getPolicyHolderDob);
  const partnerDateOfBirth = useShallowEqualSelector(getPartnerDob);

  const hasFetchedFeatureToggles = useShallowEqualSelector(getFetchedFeatureToggles);
  const hasFeatureToggleError = useShallowEqualSelector(getFeatureTogglesError);

  const performFetch = hasFetchedSession && (hasFetchedFeatureToggles || hasFeatureToggleError);

  const dispatch = useDispatch();

  useEffect(() => {
    // We need to fetch future prices based on feature toggle values. But we don't want the feature toggle lambda determining if
    // we grab current product prices. So if we have either the feature toggles or an error from the FTL lambda, continue.
    // We check for both here to avoid potentially 2 calls. Always wait for a response from the FTL
    if (performFetch && (staticProducts as ProductIds).type) {
      dispatch(fetchProductPrices((staticProducts as ProductIds).products, productType, productExcesses, ppcDate));
    }
    // ProductType and ProductExcesses will not change as they are page driven and static query respectively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType, scale, state, frequency, excess, hasFetchedSession, dispatch, performFetch, company, hasPartner, annualIncomeTier, dateOfBirth, partnerDateOfBirth]);
};
